import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Button, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#BD9463'
let black = 'rgb(38,38,38)'


let id = 'melyna-reha'
let inisial_co = 'Reha'
let inisial_ce = 'Melyna'

let lengkap_co = (<>Reha Mauren <br />Edralintus Saragih</>)
let lengkap_ce = (<>Melyna Joyce <br />Christina Simanjuntak</>)

let bapak_co = 'Bpk Eddy Sahat Saragih, S.E.'
let ibu_co = 'Ibu Dra. Hetty Purba'
let bapak_ce = "Bpk Drs. Hotman Simanjuntak, M.H."
let ibu_ce = "Ibu Shinta Tobing, A.Md"

let ig_co = "rehasan"
let ig_ce = "melynjoe"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/30/2021"

let modal = pw(id, "02.jpg")
let openlogo = pw(id, "logo1.png")

let gmaps = "https://goo.gl/maps/nq2DC3Wo3UXujQUB9"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=N2JvMXRuMTF1Zjd0NzJvNGw5MHYzaG85djIgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1504772128446!2d106.84852961476919!3d-6.2438912954803865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3baed500001%3A0xc204eed00587264!2sGKPS%20Cikoko%20(Gereja%20Kristen%20Protestan%20Simalungun)!5e0!3m2!1sid!2sid!4v1608649119408!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });


    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showrek } = this.state
        let slider = []
        let slide = [
            "01.jpg",
            "02.jpg",
            "03.jpg",
            "04.jpg",
            "05.jpg",
            "06.jpg",
            "07.jpg",
            "08.jpg",
            "09.jpg",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.6&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundColor: cmain,
                    backgroundImage: `url(${pw("asset", "mable.jpg")})`,
                    backgroundSize: '100vw 100vh',
                    backgroundAttachment: 'fixed'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            Horas!<br/>
                                            Sehubungan dengan perkembangan penyebaran Covid-19, kami sekeluarga memohon maaf karena belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara fisik pada pernikahan kami.<br/>
                                            <br/>
                                            Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i secara virtual untuk menyaksikan prosesi Pemberkatan Nikah kami.
                                            <br/><br/>
                                            Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan menyaksikan Pemberkatan Nikah kami melalui tautan kanal YOUTUBE di bawah.
                                            
                                            <Item>

<Col
    onClick={() => window.open('https://www.youtube.com/watch?v=gfUUTmWz0kM')}
    xs={10} sm={3}
    style={{
        border: `2px solid ${cmain}`,
        borderRadius: '10px',
        backgroundColor:cmain
    }}
    className="p-2 mx-sm-2 mt-3 btn">
    <Item>
        <img src="https://i.pinimg.com/originals/de/1c/91/de1c91788be0d791135736995109272a.png" className="img-fluid"
            style={{ width: "10%", height: '10%' }} />
        <p className="mb-0  my-auto ml-3" style={{ color: 'white' }}>
            <b>Live Youtube</b>
        </p>
    </Item>
</Col>
</Item>
                                            Terimakasih.

                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <img src={pw(id, "04.jpg")} className="w-100 img-fluid mb-3" />
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: 'Alex Brush, cursive' }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>

                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: 'Alex Brush, cursive', color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: 'Alex Brush, cursive', color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>

                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: 'Alex Brush, cursive', color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                   
                                    {/* <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                      </b>
                                        </p>
                                    </Item> */}
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>Pemberkatan</b><br />
                                            <span className="cblack fs16">
                                                <b>
                                                    30 Januari 2021
                                                </b><br />
                                                08.00-10.00 WIB
                                            <br /><br />
                                                <b>GKPS Cikoko</b><br />

                                            </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <b>Acara Adat </b><br />
                                            [khusus untuk keluarga]<br/>

                                            <span className="cblack fs16">
                                                <b>30 Januari 2021
                                                    </b><br />
                                                    11.00-15.30 WIB
                                                    <br /><br />
                                                <b>Grand Mangaradja </b><br />

                                            </span>


                                        </p>
                                    </Item>


                                    <Item>

                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <Item>

<Col
    onClick={() => window.open('https://www.youtube.com/watch?v=gfUUTmWz0kM')}
    xs={10} sm={3}
    style={{
        border: `2px solid ${cmain}`,
        borderRadius: '10px'
    }}
    className="p-2 mx-sm-2 mt-3 btn">
    <Item>
        <img src="https://i.pinimg.com/originals/de/1c/91/de1c91788be0d791135736995109272a.png" className="img-fluid"
            style={{ width: "10%", height: '10%' }} />
        <p className="mb-0  my-auto ml-3" style={{ color: cmain }}>
            <b>Live Youtube</b>
        </p>
    </Item>
</Col>
</Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                               
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                        Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia
                                    <br /><br />(Matius 19:6)
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />





                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, color: 'white', position: 'relative' }} className="py-3 mt-3">
                                   
                                    
                                            <Item>
                                                <p className="text-center mx-5 py-3" style={{ fontSize: '16px' }}>
                                                    Tanpa mengurangi rasa hormat, bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                            </Item>
                                            <Item>

                                                <div className="p-3 px-5 col-md-4 col-12 my-2" style={{ backgroundColor: 'white' }}>
                                                    {/* <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item> */}
                                                    <Item>
                                                        <p className="text-center" style={{ fontSize: '16px', color: cmain }}>
                                                            <b>Account Number 0700005884007 </b><br />
                                                Bank Mandiri<br />
                                                a.n.  Melyna Simanjuntak
                                            </p>
                                                    </Item>
                                                    <Item>
                                                        <CopyToClipboard text="0700005884007 ">
                                                            <div className="position-relative">

                                                                <Button style={{ backgroundColor: cmain }} onClick={() => {
                                                                    this.setState({ copy: true })
                                                                }}>
                                                                    <Item>
                                                                        <p className="px-2 mb-0" style={{ fontSize: '14px', color: 'white' }}>
                                                                            {this.state.copy ? 'Copied to Clipboard' : 'Copy Account Number'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                                                        </p>
                                                                    </Item>
                                                                </Button>


                                                            </div>
                                                        </CopyToClipboard>
                                                    </Item>

                                                </div>
                                                <div className="p-3 px-5 col-md-4 col-12 my-2" style={{ backgroundColor: 'white' }}>
                                                    {/* <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item> */}
                                        <Item>
                                        <img src="https://i.ibb.co/CvXpwht/qr-reha.png" alt="qr-reha" border="0" width="200px" height="200px"/>
                                        </Item>
                                                    <Item>
                                                        <p className="text-center" style={{ fontSize: '16px', color: cmain }}>
                                                            <b>Account Number 2211258707  </b><br />
                                                Bank BCA<br />
                                                a.n.  Reha Mauren
                                            </p>
                                                    </Item>
                                                    <Item>
                                                        <CopyToClipboard text="2211258707  ">
                                                            <div className="position-relative">

                                                                <Button style={{ backgroundColor: cmain }} onClick={() => {
                                                                    this.setState({ copy: true })
                                                                }}>
                                                                    <Item>
                                                                        <p className="px-2 mb-0" style={{ fontSize: '14px', color: 'white' }}>
                                                                            {this.state.copy ? 'Copied to Clipboard' : 'Copy Account Number'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                                                        </p>
                                                                    </Item>
                                                                </Button>


                                                            </div>
                                                        </CopyToClipboard>
                                                    </Item>

                                                </div>
                                            </Item>

                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

